// models
import { EAccessOptions } from "@/components/environment/connection-access/connection-access-modal/connection-access-modal.model";
import { EHuggingFaceEnvVariableNames, EInferenceType } from "@/models/inference.model";
import type {
  IUIInferenceCreation,
  IUIWorkloadCreation,
  IUIWorkloadModelCreation,
  IUIWorkloadSpecificEnv,
} from "@/models/workload.model";
import type { IUIWorkloadEnvSectionModel } from "@/components/section/environment-section/environment-section.model";
import type { IComputeSectionData } from "@/components/section/compute-resource-section/compute-resource-section.models";
import type { Project, Resources } from "@/swagger-models/org-unit-service-client";
import type { EnvironmentAsset, EnvironmentVariable, ModelAsset } from "@/swagger-models/assets-service-client";

// utils
import { workloadUtil } from "@/utils/workload.util/workload.util";
import { omit } from "@/utils/common.util/common.util";
import { workloadCreateFormUtil } from "@/utils/workload-create-form.util/workload-create-form.util";

export const inferenceWorkloadUtil = {
  getEmptyUIInferenceCreation,
  getEmptyUIModelCatalogInferenceCreation,
  getInferenceComputeResourceData,
  getEnvironmentRunParamsData,
  createModelCatalog,
  getHuggingFaceTokenValue,
  getRunaiModelValue,
};

function getEmptyUIModelCatalogInferenceCreation(specificEnv?: IUIWorkloadSpecificEnv): IUIWorkloadModelCreation {
  const uiWorkload: IUIWorkloadCreation = getEmptyUIInferenceCreation(specificEnv);
  const modelCreateUi: IUIWorkloadModelCreation = {
    ...uiWorkload,
    modelId: "",
    inferenceType: EInferenceType.RunaiModel,
  };
  return modelCreateUi;
}

function getEmptyUIInferenceCreation(specificEnv?: IUIWorkloadSpecificEnv): IUIInferenceCreation {
  const MIN_REPLICAS_DEFAULT_VALUE = 1;
  const MAX_REPLICAS_DEFAULT_VALUE = 1;

  const uiWorkload: IUIWorkloadCreation = workloadUtil.getEmptyUIWorkloadCreation(specificEnv);
  const uiInferenceWorkload: IUIInferenceCreation = {
    ...uiWorkload,
    specificEnv: {
      ...omit(uiWorkload.specificEnv, ["backoffLimit"]),
      autoScaleData: {
        minReplicas: MIN_REPLICAS_DEFAULT_VALUE,
        maxReplicas: MAX_REPLICAS_DEFAULT_VALUE,
        thresholdMetric: undefined,
        thresholdValue: undefined,
        scaleToZeroRetentionSeconds: undefined,
      },
      servingPortAccess: {
        authorizedUsers: null,
        authorizedGroups: null,
        accessOption: EAccessOptions.PUBLIC,
      },
    },
    inferenceType: EInferenceType.AssetsBased,
  };

  return uiInferenceWorkload;
}

function getInferenceComputeResourceData(
  workload: IUIWorkloadCreation,
  projectNodepools: Array<Resources> | undefined | null = [],
): IComputeSectionData {
  const computeData: IComputeSectionData = workloadCreateFormUtil.getComputeResourceData(workload, projectNodepools);
  computeData.autoScaleData = workload.specificEnv.autoScaleData;
  return computeData;
}

function getEnvironmentRunParamsData(workload: IUIWorkloadCreation): IUIWorkloadEnvSectionModel {
  const envSectionData: IUIWorkloadEnvSectionModel = workloadCreateFormUtil.getEnvironmentRunParamsData(workload);
  envSectionData.servingPortAccess = workload.specificEnv.servingPortAccess;
  return envSectionData;
}

function getHuggingFaceTokenValue(environmentVariables: Array<EnvironmentVariable> = []): string {
  const tokenEnv: EnvironmentVariable | undefined = environmentVariables.find(
    (env: EnvironmentVariable) => env.name === EHuggingFaceEnvVariableNames.HF_TOKEN,
  );

  return tokenEnv?.value || "";
}

function getRunaiModelValue(environmentVariables: Array<EnvironmentVariable> = []): string {
  const modelEnv: EnvironmentVariable | undefined = environmentVariables.find(
    (env: EnvironmentVariable) => env.name === EHuggingFaceEnvVariableNames.RUNAI_MODEL,
  );

  return modelEnv?.value || "";
}

function createModelCatalog(
  inference: IUIInferenceCreation,
  selectedProject: Project,
  selectedModelAsset: ModelAsset,
  createdComputeId: string,
  environment: EnvironmentAsset | null,
  supportingServingPortAccess: boolean,
): IUIWorkloadModelCreation {
  const modelCatalog: IUIWorkloadModelCreation = {
    modelId: selectedModelAsset.meta.id,
    clusterId: inference.clusterId,
    projectId: inference.projectId,
    namespace: selectedProject?.status.namespace || inference.namespace,
    name: inference.name,
    assets: {
      compute: createdComputeId || "",
      environment: selectedModelAsset.spec.assets?.environment || null,
    },
    specificEnv: {
      ...inference.specificEnv,
      environmentVariables:
        inference.specificEnv.environmentVariables || environment?.spec.environmentVariables || undefined,
    },
    inferenceType: EInferenceType.RunaiModel,
  };

  if (!modelCatalog.specificEnv.nodePools && selectedProject.effective.defaultNodePools) {
    modelCatalog.specificEnv.nodePools = selectedProject.effective.defaultNodePools;
  }

  // Remove servingPortAccess if not supported
  if (!supportingServingPortAccess) {
    delete modelCatalog.specificEnv.servingPortAccess;
  }

  return modelCatalog;
}
