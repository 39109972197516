/* tslint:disable */
/* eslint-disable */
/**
 * cli-exposer
 * An API for managing RunAI Researcher Command Line Interface
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: support@run.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Command
 */
export interface Command {
    /**
     * The commands that can be used to install the Run:AI CLI.
     * @type {string}
     * @memberof Command
     */
    'command': string;
}
/**
 * 
 * @export
 * @interface ComplianceIssues
 */
export interface ComplianceIssues {
    /**
     * 
     * @type {Array<ComplianceIssuesComplianceIssuesInner>}
     * @memberof ComplianceIssues
     */
    'complianceIssues'?: Array<ComplianceIssuesComplianceIssuesInner>;
}
/**
 * 
 * @export
 * @interface ComplianceIssuesComplianceIssuesInner
 */
export interface ComplianceIssuesComplianceIssuesInner {
    /**
     * 
     * @type {string}
     * @memberof ComplianceIssuesComplianceIssuesInner
     */
    'field': string;
    /**
     * 
     * @type {string}
     * @memberof ComplianceIssuesComplianceIssuesInner
     */
    'details': string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface SubmissionError
 */
export interface SubmissionError {
    /**
     * 
     * @type {number}
     * @memberof SubmissionError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof SubmissionError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionError
     */
    'details'?: string;
    /**
     * 
     * @type {Array<ComplianceIssuesComplianceIssuesInner>}
     * @memberof SubmissionError
     */
    'complianceIssues'?: Array<ComplianceIssuesComplianceIssuesInner>;
}
/**
 * 
 * @export
 * @interface Version
 */
export interface Version {
    /**
     * The version of the CLI SDK
     * @type {string}
     * @memberof Version
     */
    'sdkVersion': string;
    /**
     * The version of the CP
     * @type {string}
     * @memberof Version
     */
    'cpVersion': string;
    /**
     * Whether the CLI needs to be upgraded
     * @type {boolean}
     * @memberof Version
     */
    'needUpgrade': boolean;
}

/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @summary health check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<void> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @summary health check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public health(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ResearcherCommandLineInterfaceApi - axios parameter creator
 * @export
 */
export const ResearcherCommandLineInterfaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint returns a binary file that run the Run:AI CLI. 
         * @summary Download RunAI Researcher command line binary
         * @param {'linux' | 'windows' | 'darwin'} operatingSystem The operating system name.
         * @param {string} architecture The architecture type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBinary: async (operatingSystem: 'linux' | 'windows' | 'darwin', architecture: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'operatingSystem' is not null or undefined
            assertParamExists('getBinary', 'operatingSystem', operatingSystem)
            // verify required parameter 'architecture' is not null or undefined
            assertParamExists('getBinary', 'architecture', architecture)
            const localVarPath = `/api/v1/cli/dist/{operatingSystem}/{architecture}/runai`
                .replace(`{${"operatingSystem"}}`, encodeURIComponent(String(operatingSystem)))
                .replace(`{${"architecture"}}`, encodeURIComponent(String(architecture)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns a Linux script that can be used to install the Run:AI CLI. 
         * @summary Download Linux installer script
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallerLinux: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cli/installer/linux`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns a linux script commands that can be used to install the Run:AI CLI. 
         * @summary Get Linux installer script commands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallerLinuxCommands: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cli/installer/linux/commands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns a Mac script that can be used to install the Run:AI CLI. 
         * @summary Download Mac installer script
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallerMac: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cli/installer/mac`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns a Mac script commands that can be used to install the Run:AI CLI. 
         * @summary Get Mac installer script commands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallerMacCommands: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cli/installer/mac/commands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns a unix script that can be used to install the Run:AI CLI. 
         * @summary Download Unix installer script
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallerUnix: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cli/installer/unix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns a unix script commands that can be used to install the Run:AI CLI. 
         * @summary Get Unix installer script commands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallerUnixCommands: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cli/installer/unix/commands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns a windows script commands that can be used to install the Run:AI CLI. 
         * @summary Get Windows MSI installer script commands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallerWindowsCommands: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cli/installer/windows/commands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns a document of help for the Run:AI CLI. 
         * @summary Get CLI document by name
         * @param {string} documentName The manual document name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManualDocument: async (documentName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentName' is not null or undefined
            assertParamExists('getManualDocument', 'documentName', documentName)
            const localVarPath = `/api/v1/cli/docs/{documentName}`
                .replace(`{${"documentName"}}`, encodeURIComponent(String(documentName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint compares the latest version of the CLI with the current local version.  If the local version is outdated, the endpoint returns the latest version. 
         * @summary Get CLI latest version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cli/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResearcherCommandLineInterfaceApi - functional programming interface
 * @export
 */
export const ResearcherCommandLineInterfaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ResearcherCommandLineInterfaceApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint returns a binary file that run the Run:AI CLI. 
         * @summary Download RunAI Researcher command line binary
         * @param {'linux' | 'windows' | 'darwin'} operatingSystem The operating system name.
         * @param {string} architecture The architecture type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBinary(operatingSystem: 'linux' | 'windows' | 'darwin', architecture: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBinary(operatingSystem, architecture, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns a Linux script that can be used to install the Run:AI CLI. 
         * @summary Download Linux installer script
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstallerLinux(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstallerLinux(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns a linux script commands that can be used to install the Run:AI CLI. 
         * @summary Get Linux installer script commands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstallerLinuxCommands(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Command>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstallerLinuxCommands(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns a Mac script that can be used to install the Run:AI CLI. 
         * @summary Download Mac installer script
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstallerMac(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstallerMac(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns a Mac script commands that can be used to install the Run:AI CLI. 
         * @summary Get Mac installer script commands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstallerMacCommands(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Command>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstallerMacCommands(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns a unix script that can be used to install the Run:AI CLI. 
         * @summary Download Unix installer script
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstallerUnix(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstallerUnix(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns a unix script commands that can be used to install the Run:AI CLI. 
         * @summary Get Unix installer script commands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstallerUnixCommands(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Command>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstallerUnixCommands(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns a windows script commands that can be used to install the Run:AI CLI. 
         * @summary Get Windows MSI installer script commands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstallerWindowsCommands(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Command>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstallerWindowsCommands(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns a document of help for the Run:AI CLI. 
         * @summary Get CLI document by name
         * @param {string} documentName The manual document name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManualDocument(documentName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManualDocument(documentName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint compares the latest version of the CLI with the current local version.  If the local version is outdated, the endpoint returns the latest version. 
         * @summary Get CLI latest version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Version>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ResearcherCommandLineInterfaceApi - factory interface
 * @export
 */
export const ResearcherCommandLineInterfaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ResearcherCommandLineInterfaceApiFp(configuration)
    return {
        /**
         * This endpoint returns a binary file that run the Run:AI CLI. 
         * @summary Download RunAI Researcher command line binary
         * @param {'linux' | 'windows' | 'darwin'} operatingSystem The operating system name.
         * @param {string} architecture The architecture type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBinary(operatingSystem: 'linux' | 'windows' | 'darwin', architecture: string, options?: any): AxiosPromise<any> {
            return localVarFp.getBinary(operatingSystem, architecture, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns a Linux script that can be used to install the Run:AI CLI. 
         * @summary Download Linux installer script
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallerLinux(options?: any): AxiosPromise<string> {
            return localVarFp.getInstallerLinux(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns a linux script commands that can be used to install the Run:AI CLI. 
         * @summary Get Linux installer script commands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallerLinuxCommands(options?: any): AxiosPromise<Command> {
            return localVarFp.getInstallerLinuxCommands(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns a Mac script that can be used to install the Run:AI CLI. 
         * @summary Download Mac installer script
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallerMac(options?: any): AxiosPromise<string> {
            return localVarFp.getInstallerMac(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns a Mac script commands that can be used to install the Run:AI CLI. 
         * @summary Get Mac installer script commands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallerMacCommands(options?: any): AxiosPromise<Command> {
            return localVarFp.getInstallerMacCommands(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns a unix script that can be used to install the Run:AI CLI. 
         * @summary Download Unix installer script
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallerUnix(options?: any): AxiosPromise<string> {
            return localVarFp.getInstallerUnix(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns a unix script commands that can be used to install the Run:AI CLI. 
         * @summary Get Unix installer script commands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallerUnixCommands(options?: any): AxiosPromise<Command> {
            return localVarFp.getInstallerUnixCommands(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns a windows script commands that can be used to install the Run:AI CLI. 
         * @summary Get Windows MSI installer script commands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallerWindowsCommands(options?: any): AxiosPromise<Command> {
            return localVarFp.getInstallerWindowsCommands(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns a document of help for the Run:AI CLI. 
         * @summary Get CLI document by name
         * @param {string} documentName The manual document name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManualDocument(documentName: string, options?: any): AxiosPromise<string> {
            return localVarFp.getManualDocument(documentName, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint compares the latest version of the CLI with the current local version.  If the local version is outdated, the endpoint returns the latest version. 
         * @summary Get CLI latest version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(options?: any): AxiosPromise<Version> {
            return localVarFp.getVersion(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ResearcherCommandLineInterfaceApi - object-oriented interface
 * @export
 * @class ResearcherCommandLineInterfaceApi
 * @extends {BaseAPI}
 */
export class ResearcherCommandLineInterfaceApi extends BaseAPI {
    /**
     * This endpoint returns a binary file that run the Run:AI CLI. 
     * @summary Download RunAI Researcher command line binary
     * @param {'linux' | 'windows' | 'darwin'} operatingSystem The operating system name.
     * @param {string} architecture The architecture type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResearcherCommandLineInterfaceApi
     */
    public getBinary(operatingSystem: 'linux' | 'windows' | 'darwin', architecture: string, options?: AxiosRequestConfig) {
        return ResearcherCommandLineInterfaceApiFp(this.configuration).getBinary(operatingSystem, architecture, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns a Linux script that can be used to install the Run:AI CLI. 
     * @summary Download Linux installer script
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResearcherCommandLineInterfaceApi
     */
    public getInstallerLinux(options?: AxiosRequestConfig) {
        return ResearcherCommandLineInterfaceApiFp(this.configuration).getInstallerLinux(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns a linux script commands that can be used to install the Run:AI CLI. 
     * @summary Get Linux installer script commands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResearcherCommandLineInterfaceApi
     */
    public getInstallerLinuxCommands(options?: AxiosRequestConfig) {
        return ResearcherCommandLineInterfaceApiFp(this.configuration).getInstallerLinuxCommands(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns a Mac script that can be used to install the Run:AI CLI. 
     * @summary Download Mac installer script
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResearcherCommandLineInterfaceApi
     */
    public getInstallerMac(options?: AxiosRequestConfig) {
        return ResearcherCommandLineInterfaceApiFp(this.configuration).getInstallerMac(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns a Mac script commands that can be used to install the Run:AI CLI. 
     * @summary Get Mac installer script commands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResearcherCommandLineInterfaceApi
     */
    public getInstallerMacCommands(options?: AxiosRequestConfig) {
        return ResearcherCommandLineInterfaceApiFp(this.configuration).getInstallerMacCommands(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns a unix script that can be used to install the Run:AI CLI. 
     * @summary Download Unix installer script
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResearcherCommandLineInterfaceApi
     */
    public getInstallerUnix(options?: AxiosRequestConfig) {
        return ResearcherCommandLineInterfaceApiFp(this.configuration).getInstallerUnix(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns a unix script commands that can be used to install the Run:AI CLI. 
     * @summary Get Unix installer script commands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResearcherCommandLineInterfaceApi
     */
    public getInstallerUnixCommands(options?: AxiosRequestConfig) {
        return ResearcherCommandLineInterfaceApiFp(this.configuration).getInstallerUnixCommands(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns a windows script commands that can be used to install the Run:AI CLI. 
     * @summary Get Windows MSI installer script commands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResearcherCommandLineInterfaceApi
     */
    public getInstallerWindowsCommands(options?: AxiosRequestConfig) {
        return ResearcherCommandLineInterfaceApiFp(this.configuration).getInstallerWindowsCommands(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns a document of help for the Run:AI CLI. 
     * @summary Get CLI document by name
     * @param {string} documentName The manual document name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResearcherCommandLineInterfaceApi
     */
    public getManualDocument(documentName: string, options?: AxiosRequestConfig) {
        return ResearcherCommandLineInterfaceApiFp(this.configuration).getManualDocument(documentName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint compares the latest version of the CLI with the current local version.  If the local version is outdated, the endpoint returns the latest version. 
     * @summary Get CLI latest version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResearcherCommandLineInterfaceApi
     */
    public getVersion(options?: AxiosRequestConfig) {
        return ResearcherCommandLineInterfaceApiFp(this.configuration).getVersion(options).then((request) => request(this.axios, this.basePath));
    }
}


